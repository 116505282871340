export const company = {
  seotitle: 'Dynamic Coach Travel',
  seodescription:
    'Dynamic Coach Travel is a budget-friendly travel service that offers comfortable and reliable transportation options for passengers. Catering to a wide range of travelers, from daily commuters to occasional tourists, Dynamic Coach Travel ensures a pleasant journey with clean, well-maintained buses, friendly drivers.it provides great value without compromising on quality or safety, making it an ideal choice for those seeking travel.',
  name: 'Dynamic Coach',
  address: {
    building: 'Unit 4A, The Blackstaff Stop,',
    street: '79-129 Springfield Road',
    town: 'Belfast',
    postcode: 'BT12 7AE',
  },
  cookies_name: 'DynamicCoachTravelCookies',
  apptitle: 'Dynamic Coach Taxis App',
  apple_store_url: '#',
  google_store_url: '#',
  company_email: 'info@dynamiccoachtravel.co.uk',
  tel_number: '(+44) 7988 598644',
  dpo_email: 'info@Dynamic.io',
  dpo: '',
  vat_number: '-',
  compnay_number: '(+44)7776198',
  website: 'https://dynamiccoachtravel.co.uk/',
  url: 'https://dynamiccoachtravel.co.uk/',
  dpo_reg_number: '-',
  updated_on: '17/07/2023',
  Copyright: 'Copyright 2023 | Dynamic Coach | All Rights Reserved',
  logo_url: '/assets/images/logo.png',
  logo_alt: 'Dynamic Coach Logo',
  width: '128',
  height: '45',
  width_mobile: '180',
  height_mobile: '70',
  contactus_task_url: 'https://',
  Facebook: '#',
  Instagram: '#',
  Twitter: '#',
  YouTube: '#',
  LinkedIn: '#',
  iframeMaps: (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2311.3617380452256!2d-5.978945622648395!3d54.59761977267851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4861087fa08bac33%3A0xcfb43d859512fa58!2sSpringfield%20Rd%2C%20Belfast%2C%20UK!5e0!3m2!1sen!2s!4v1720519562753!5m2!1sen!2s"
      width="100%"
      height="450"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  ),
};
// Check List - make sure update below files before making this website live
// 1- robots_txt: 'update robots.txt file domain details
// 1-   robots_txt: 'update robots.txt file domain details
// 2-  regenarate manifest.json file and icons and put in public folder
